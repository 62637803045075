import axios from 'axios'

export const logTestedClient = async (data) => {
  const res = await axios.post('/api/logTestedClient', data)
  return res.data
}

export const getAllPlatformList = async () => {
  const res = await axios.get('/api/getPlatformList')
  return res.data
}

export const getGameListByPlatform = async (platform) => {
  const res = await axios.get(`/api/getGameListByPlatform?platform=${platform}`)
  return res.data
}

export const getTableIdByPlatformGameCode = async (platform, gameCode) => {
  const res = await axios.get(`/api/getTableIdByPlatformGameCode?platform=${platform}&gameCode=${gameCode}`)
  return res.data
}

export const getLanguageList = async () => {
  const res = await axios.get('/api/getLanguageList')
  return res.data
}
