import axios from 'axios'

export const login = async (data) => {
  try {
    const res = await axios.post('/api/testerportal/login', data)
    const { token, refreshToken } = res.data
    localStorage.setItem('token', token)
    localStorage.setItem('refreshToken', refreshToken)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const refreshAuthToken = async () => {
  try {
    const res = await axios.get('/api/testerportal/token', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
      },
    })
    console.log('refreshToken res.data', res.data)
    const { token, refreshToken } = res.data
    localStorage.setItem('token', token)
    if (refreshToken) localStorage.setItem('refreshToken', refreshToken)
    return res.data
  } catch (error) {
    console.error(error)
    const { data } = error.response || {}
    return data
  }
}

export const logout = async () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('lastActivity')
}
