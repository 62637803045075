<template>
  <AntRow
    v-if="!loginUserData.userId"
    class="login-row"
    justify="center"
  >
    <AntCol
      :sm="{ span: 24 }"
      :md="{ span: 20 }"
      :lg="{ span: 20 }"
      :xl="{ span: 6 }"
    >
      <div class="logo">
        <img :src="require('@/assets/testerportal_logo.svg')" />
      </div>
      <FormTemplate
        :defaultData="loginUserData"
        :formFields="formFields"
        :wrapperCol="{ span: 24 }"
        buttonText="Login"
        :onSubmit="onSubmit"
      />
      <AntAlert
        v-if="!!alertState.message"
        class="warning-message"
        :type="alertState.type"
        :message="alertState.message"
        showIcon
      />
    </AntCol>
  </AntRow>
</template>

<script>
import moment from 'moment'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { jwtDecode } from 'jwt-decode'

import {
  Alert as AntAlert,
  Col as AntCol,
  Row as AntRow,
} from 'ant-design-vue'

import FormTemplate from '@/components/AgentManagement/FormTemplate'
import { login } from '@/api/testerportal/login'

export default {
  name: 'Login',
  components: {
    AntRow,
    AntCol,
    AntAlert,
    FormTemplate,
  },
  props: {
    loginUserData: Object,
    onChangeLoginUserData: Function,
  },
  setup(props) {
    const router = useRouter()
    const alertState = reactive({})

    if (!localStorage.getItem('token') && props.loginUserData.userId) props.onChangeLoginUserData() // empty loginUserData

    const formFields = [
      {
        id: 'userId',
        placeholder: 'User ID',
        defaultValue: undefined,
        element: 'input',
        inputType: 'text',
        maxlength: 21,
        rules: [
          {
            pattern: /^[A-Za-z0-9]+$/,
            message: 'Please enter the correct format',
            trigger: 'blur',
          },
          {
            required: true,
            message: 'Required',
          },
        ],
      },
      {
        id: 'password',
        placeholder: 'Password',
        defaultValue: undefined,
        element: 'input-password',
        inputType: 'text',
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ],
      },
    ]
    const onSubmit = async (formState) => {
      let data
      let callLogin = false

      const now = moment().unix()
      const token = localStorage.getItem('token')

      if (token) {
        const decodedToken = jwtDecode(token)
        const isDifferentUserId = decodedToken.userId !== formState.userId
        const isTokenAlmostExpired = !isDifferentUserId && decodedToken.exp - now < 30 * 60
        // console.log('Login onSubmit', { isDifferentUserId, isTokenAlmostExpired })
        if (isDifferentUserId || isTokenAlmostExpired) {
          callLogin = true
        } else {
          data = decodedToken
        }
      } else {
        callLogin = true
      }

      let message
      if (callLogin) ({ data, message } = await login(formState))

      if (data) {
        localStorage.setItem('lastActivity', now)
        props.onChangeLoginUserData(data)
        const prevRoute = router.getRoutes().find(({ path }) => path === window.history.state.back)
        // console.log('prevRoute', prevRoute)
        if (prevRoute?.meta?.requiresAuth) {
          router.back()
        } else {
          router.push('/testerportal/player-list')
        }
      } else {
        alertState.type = 'error'
        alertState.message = message
      }
    }

    return {
      formFields,
      alertState,
      onSubmit,
    }
  },
}
</script>

<style scoped>
.login-row {
  height: 100vh;
  background: #002140;
  padding: 64px 24px;
}

.logo {
  text-align: center;
}

.logo img {
  max-width: 200px;
}
</style>
