<template>
  <AntRow>
    <AntCol
      :sm="{ span: 24, offset: 0 }"
      :md="{ span: 20, offset: 2 }"
      :lg="{ span: 16, offset: 4 }"
    >
    <AntRadioGroup
      :value="selectedLoginFunction"
      class="login-function-buttons"
      buttonStyle="solid"
      @change="onChangeLoginFunction"
    >
      <AntRadioButton class="login-function-button" value="login" block>login</AntRadioButton>
      <AntRadioButton class="login-function-button" value="doLoginAndLaunchGame" block>doLoginAndLaunchGame</AntRadioButton>
    </AntRadioGroup>

      <FormTemplate
        :formFields="formFields"
        :onSubmit="onSubmit"
        buttonText="Login"
      />
      <AntAlert
        v-if="!!alertState.message"
        :type="alertState.type"
        :message="alertState.message"
        showIcon
        @close="() => {}"
      >
        <!-- <template #description>
          <div v-html="warningMessages.join('<br>')" />
        </template> -->
      </AntAlert>
    </AntCol>
  </AntRow>
</template>

<script>
import {
  ref,
  reactive,
  onBeforeMount,
} from 'vue'

import { useRouter } from 'vue-router'
import axios from 'axios'
import { isEqual } from 'lodash'

import {
  Row as AntRow,
  Col as AntCol,
  Radio as AntRadio,
  Alert as AntAlert,
} from 'ant-design-vue'

import { getAllPlatformList } from '@/api/gas'
import { getUser, updateUser } from '@/api/tssw'

import { AWC_ENV_LIST } from '@/constants/index'

import {
  AGENT_CONFIG,
  LOGIN_FORM_FIELDS,
  DO_LOGIN_FORM_FIELDS,
} from '@/constants/tssw'

import FormTemplate from '@/components/AgentManagement/FormTemplate'

export default {
  name: 'Login',
  components: {
    AntRow,
    AntCol,
    AntRadioButton: AntRadio.Button,
    AntRadioGroup: AntRadio.Group,
    AntAlert,
    FormTemplate,
  },
  props: {
    awcEnv: String,
    requestResponseData: Object,
  },
  emits: ['changeLoginUserData'],
  inheritAttrs: false,
  setup(props, { emit }) {
    const router = useRouter()

    const selectedLoginFunction = ref('login')
    const requestResponseData = reactive(props.requestResponseData)
    const alertState = reactive({})

    const formFieldsMap = {
      login: LOGIN_FORM_FIELDS,
      doLoginAndLaunchGame: DO_LOGIN_FORM_FIELDS,
    }
    const formFields = reactive([...formFieldsMap[selectedLoginFunction.value]])

    const platformOptions = ref([])

    const getPlatformOptions = async () => {
      const platformIdx = formFields.findIndex(({ id }) => id === 'platform')
      formFields[platformIdx].loading = true

      const platformList = await getAllPlatformList()
      platformOptions.value = platformList.map(platform => ({ label: platform, value: platform }))

      formFields[platformIdx].loading = false
      formFields[platformIdx].options = platformOptions.value
    }

    onBeforeMount(getPlatformOptions)

    const onChangeLoginFunction = (e) => {
      const { value } = e.target
      selectedLoginFunction.value = value

      formFields.length = 0
      formFields.push(...formFieldsMap[value])

      const platformIdx = formFields.findIndex(({ id }) => id === 'platform')
      if (!formFields[platformIdx].options?.length) {
        formFields[platformIdx].options = platformOptions.value
        formFields[platformIdx].loading = false
      }
    }

    const onSubmit = async (formState) => {
      const { awcEnv } = props

      const {
        userId,
        betLimit,
        autoBetMode,
      } = formState

      const { data: getUserData, status, message } = await getUser({ userId, env: awcEnv })

      if (status === 'success') {
        const URL = `${AWC_ENV_LIST[awcEnv].domain}/wallet/${selectedLoginFunction.value}`

        const requestData = {
          cert: AGENT_CONFIG[awcEnv].cert,
          agentId: AGENT_CONFIG[awcEnv].agentId,
          ...formState,
          autoBetMode: autoBetMode ? '1' : '0',
        }

        const { data: { data: awcData } } = await axios.post(`/proxy/${URL}`, requestData)

        if (awcData) {
          requestResponseData.url = URL
          requestResponseData.request = JSON.stringify(requestData)
          requestResponseData.response = JSON.stringify(awcData)

          if (awcData.status === '0000') {
            alertState.type = 'success'
            alertState.message = `User ID: ${userId} login successfully`

            emit('changeLoginUserData', {
              ...getUserData,
              loginUrl: awcData.url,
              loginFunction: selectedLoginFunction.value,
              loginRequestData: requestData,
            })

            if (betLimit) {
              const formBetLimit = JSON.parse(betLimit)
              const userBetLimit = JSON.parse(getUserData.betLimit || '{}')

              let shouldUpdateBetlimit = false
              Object.entries(formBetLimit).forEach(([platform, platformBetLimit]) => {
                if (!isEqual(userBetLimit[platform], platformBetLimit)) {
                  shouldUpdateBetlimit = true
                  userBetLimit[platform] = platformBetLimit
                }
              })

              if (shouldUpdateBetlimit) {
                const { data: updateUserData } = await updateUser({
                  userId,
                  env: awcEnv,
                  betLimit: JSON.stringify(userBetLimit),
                })
                emit('changeLoginUserData', updateUserData)
              }
            }

            const prevRoute = router.getRoutes().find(({ path }) => path === window.history.state.back)
            if (prevRoute?.meta?.needLogin) {
              router.back()
            } else {
              router.push('/tssw/user-settings')
            }
          } else {
            alertState.type = 'error'
            alertState.message = JSON.stringify(awcData)
          }
        }
      } else {
        alertState.type = 'error'
        alertState.message = message
      }
    }

    return {
      formFields,
      alertState,
      selectedLoginFunction,
      onChangeLoginFunction,
      onSubmit,
    }
  },
}
</script>

<style scoped>
.login-function-buttons {
  display: flex;
  margin-bottom: 24px;
}

.login-function-button {
  width: 100%;
  text-align: center;
}
</style>
